// this is copy-pasted from https://docs.google.com/spreadsheets/d/1BIx_YgpvaHqECkh0BWcXO3inV2HbX_dZjX5g8eRCC9o/edit#gid=1895899163

module.exports = [
  { en: '/index', fr: '/fr/index', de: '/de/index', redirect: '/' },
  { en: '/', fr: '/fr', de: '/de', Component: 'HomePage' },
  { en: '/login', fr: '/fr/connexion', de: '/de/anmeldung', Component: 'AccountLoginPageComponent', id: 'login' },
  { en: '/signup', fr: '/fr/creer-un-compte', de: '/de/kontoerstellung', Component: 'AccountCreationComponent' },
  {
    en: '/lp/signup',
    fr: '/fr/lp/creer-un-compte',
    de: '/de/lp/kontoerstellung',
    Component: 'AccountCreationComponent',
    lp: 'lpSignup',
  },
  {
    en: '/signup/:activationKey',
    fr: '/fr/creer-un-compte/:activationKey',
    de: '/de/kontoerstellung/:activationKey',
    Component: 'AccountCreationComponent',
  },
  {
    en: '/forgot-password',
    fr: '/fr/reinitialiser-le-mot-de-passe',
    de: '/de/passwort-zurucksetzen',
    Component: 'AccountForgotComponent',
  },
  {
    en: '/forgot-password/:key',
    fr: '/fr/reinitialiser-le-mot-de-passe/:key',
    de: '/de/passwort-zurucksetzen/:key',
    Component: 'AccountForgotComponent',
  },
  {
    en: '/resend-confirmation',
    fr: '/fr/renvoyer-confirmation',
    de: '/de/bestatigung-senden',
    Component: 'AccountResendConfirmationComponent',
    id: 'account-resend-confirmation',
  },
  {
    en: '/activate/:activationKey',
    fr: '/fr/activer/:activationKey',
    de: '/de/aktivierung/:activationKey',
    Component: 'ActivateComponent',
  },
  {
    en: '/account/products',
    fr: '/fr/compte/produits',
    de: '/de/konto/produkte',
    Component: 'Account',
    id: 'account-products',
  },
  {
    en: '/blueprints',
    fr: '/fr/blueprints',
    de: '/de/blueprints',
    Component: 'ExportBlueprintComponent',
    id: 'blueprints',
    hideOnProd: 'hidden',
  },
  {
    en: '/account/products/success',
    fr: '/fr/compte/produits/success',
    de: '/de/konto/produkte/success',
    Component: 'Account',
    id: 'account-products',
  },
  {
    en: '/account/image-upload',
    fr: '/fr/compte/televersement-d-image',
    de: '/de/konto/build-hochladen',
    Component: 'Account',
    id: 'account-upload',
  },
  {
    en: '/account/recruit-a-friend',
    fr: '/fr/compte/recrutez-un-ami',
    de: '/de/konto/freunde-anwerben',
    Component: 'Account',
    id: 'account-recruit',
  },
  {
    en: '/account/pts',
    fr: '/fr/compte/pts',
    de: '/de/konto/pts',
    Component: 'Account',
    id: 'account-pts',
  },
  {
    en: '/account/backer',
    fr: '/fr/account/backer',
    de: '/de/account/backer',
    Component: 'Account',
    id: 'account-backer',
  },
  {
    en: '/account/profile',
    fr: '/fr/compte/profile',
    de: '/de/konto/profile',
    Component: 'Account',
    id: 'account-profile',
  },
  {
    en: '/account/profile/:key',
    fr: '/fr/compte/profile/:key',
    de: '/de/konto/profile/:key',
    Component: 'Account',
    id: 'account-profile',
  },
  {
    en: '/account/preferences',
    fr: '/fr/compte/preferences',
    de: '/de/konto/preferences',
    Component: 'Account',
    id: 'account-preferences',
  },
  {
    en: '/account/security',
    fr: '/fr/compte/security',
    de: '/de/konto/security',
    Component: 'Account',
    id: 'account-security',
  },
  {
    en: '/account/dacs',
    fr: '/fr/compte/dacs',
    de: '/de/konto/dacs',
    Component: 'Account',
    id: 'account-dacs',
  },
  {
    en: '/account/payments',
    fr: '/fr/compte/payments',
    de: '/de/konto/payments',
    Component: 'Account',
    id: 'account-payments',
  },
  {
    en: '/account/linked',
    fr: '/fr/compte/linked',
    de: '/de/konto/linked',
    Component: 'Account',
    id: 'account-linked',
  },
  {
    en: '/account/download',
    fr: '/fr/compte/download',
    de: '/de/konto/download',
    Component: 'Account',
    id: 'account-download',
  },
  {
    en: '/account/download-server',
    fr: '/fr/compte/download-server',
    de: '/de/konto/download-server',
    Component: 'Account',
    id: 'account-download-server',
  },
  { en: '/news', fr: '/fr/actualites', de: '/de/neuigkeiten', Component: 'NewsComponent' },
  { en: '/news/archives', fr: '/fr/actualites/archives', de: '/de/archiv', Component: 'NewsArchivesComponent' },
  {
    en: '/news/:uid',
    fr: '/fr/actualites/:uid',
    de: '/de/neuigkeiten/:uid',
    Component: 'NewsDetailComponent',
    id: 'news-detail',
  },
  { en: '/buy', fr: '/fr/acheter', de: '/de/kaufen', Component: 'BuyPageOne', id: 'buy' },
  { en: '/buy/confirm', fr: '/fr/acheter/confirmer', de: '/de/bestatigen', Component: 'BuyPageOne', id: 'buy-confirm' },
  {
    en: '/buy/thankyou',
    fr: '/fr/acheter/merci',
    de: '/de/kaufen/danke',
    Component: 'BuyPageThankyou',
    id: 'buy-thankyou',
  },
  {
    en: '/play/:lp/thankyou',
    fr: '/fr/play/:lp/merci',
    de: '/de/play/:lp/danke',
    Component: 'BuyPageThankyou',
    id: 'buy-thankyou-lp',
    hideOnProd: 'hidden',
  },
  {
    en: '/play/:lp/thankyou/:experiment/:variant',
    fr: '/fr/play/:lp/merci/:experiment/:variant',
    de: '/de/play/:lp/danke/:experiment/:variant',
    Component: 'BuyPageThankyou',
    id: 'buy-thankyou-lp-exp',
    hideOnProd: 'hidden',
  },
  { en: '/recruit-a-friend', fr: '/fr/recrutez-un-ami', de: '/de/freunde-anwerben', Component: 'ReferalHomeComponent' },
  { en: '/contact', fr: '/fr/contact', de: '/de/kontakt', Component: 'PressContactComponent', id: 'contact' },
  {
    en: '/contact/:uid',
    fr: '/fr/contact/:uid',
    de: '/de/kontakt/:uid',
    Component: 'NewsDetailComponent',
    id: 'contact-detail',
  },
  {
    en: '/legal/privacy-policy',
    fr: '/fr/legal/politique-de-confidentialite',
    de: '/de/legal/datenschutzrichtlinie',
    Component: 'LegalComponent',
    id: 'legal-privacy',
  },
  {
    en: '/legal/privacy-policy-myDU',
    fr: '/fr/legal/politique-de-confidentialite-myDU',
    de: '/de/legal/datenschutzrichtlinie-myDU',
    Component: 'LegalComponent',
    id: 'legal-privacy-myDU',
  },
  {
    en: '/legal/terms-and-conditions',
    fr: '/fr/legal/conditions-d-utilisation',
    de: '/de/legal/nutzungsbedingungen',
    Component: 'LegalComponent',
    id: 'legal-terms',
  },
  {
    en: '/legal/eula',
    fr: '/fr/legal/cluf',
    de: '/de/legal/endbenutzer-lizenzvereinbarung',
    Component: 'LegalComponent',
    id: 'legal-eula',
  },
  {
    en: '/legal/eula-myDU',
    fr: '/fr/legal/cluf-myDU',
    de: '/de/legal/endbenutzer-lizenzvereinbarung-myDU',
    Component: 'LegalComponent',
    id: 'legal-eula-myDU',
  },
  {
    en: '/legal/code-of-conduct',
    fr: '/fr/legal/code-de-conduite',
    de: '/de/legal/verhaltenskodex',
    Component: 'LegalComponent',
    id: 'legal-code',
  },
  {
    en: '/legal/cookie-policy',
    fr: '/fr/legal/politique-en-matiere-de-temoins',
    de: '/de/legal/cookie-richtlinie',
    Component: 'LegalComponent',
    id: 'legal-cookie',
  },
  {
    en: '/legal/legal-information',
    fr: '/fr/legal/legal-information',
    de: '/de/legal/legal-information',
    Component: 'LegalComponent',
    id: 'legal-information',
  },
  {
    en: '/legal/forum-and-social-channels-rules-of-conduct',
    fr: '/fr/legal/charte-de-bonne-conduite-relative-a-nos-forums-et-a-nos-reseaux-sociaux',
    de: '/de/legal/verhaltensregeln-fur-foren-und-social-media-kanale',
    Component: 'LegalComponent',
    id: 'legal-conduct',
  },
  {
    en: '/content-creators',
    fr: '/fr/createurs-de-contenu',
    de: '/de/content-creator',
    Component: 'ContentCreatorComponent',
  },
  { en: '/server-status', fr: '/fr/etat-du-serveur', de: '/de/serverstatus', Component: 'ServerStatusComponent' },
  {
    en: '/tutorials',
    fr: '/fr/tutoriels',
    de: '/de/tutorials',
    Component: 'TutorialsPageComponent',
  },
  {
    en: '/tutorials/:slug',
    fr: '/fr/tutoriels/:slug',
    de: '/de/tutorials/:slug',
    Component: 'TutorialsDetailsPageComponent',
  },
  {
    en: '/site-map',
    fr: '/fr/plan-du-site',
    de: '/de/sitemap',
    Component: 'SitemapPageComponent',
  },
  {
    en: '/play/lp1',
    fr: '/fr/play/lp1',
    de: '/de/play/lp1',
    Component: 'LandingOneHome',
    lp: 'lp1',
    hideOnProd: 'hidden',
  },
  {
    en: '/play/lp1/buy',
    fr: '/fr/play/lp1/acheter',
    de: '/de/play/lp1/kaufen',
    Component: 'LandingOneBuyOne',
    lp: 'lp1',
    buy: 'buy',
    hideOnProd: 'hidden',
  },
  {
    en: '/play/lp1/confirm',
    fr: '/fr/play/lp1/confirmer',
    de: '/de/play/lp1/bestatigen',
    Component: 'LandingOneBuyTwo',
    lp: 'lp1',
    hideOnProd: 'hidden',
  },
  {
    en: '/play/lp1/buy2',
    fr: '/fr/play/lp1/acheter2',
    de: '/de/play/lp1/kaufen2',
    Component: 'LandingOneBuyOne',
    lp: 'lp1',
    buy: 'buy2',
    hideOnProd: 'hidden',
  },
  {
    en: '/play/lp2',
    fr: '/fr/play/lp2',
    de: '/de/play/lp2',
    Component: 'LandingOneHome',
    lp: 'lp2',
    hideOnProd: 'hidden',
  },
  {
    en: '/play/lp2/buy',
    fr: '/fr/play/lp2/acheter',
    de: '/de/play/lp2/kaufen',
    Component: 'LandingOneBuyOne',
    lp: 'lp2',
    buy: 'buy',
    hideOnProd: 'hidden',
  },
  {
    en: '/play/lp2/confirm',
    fr: '/fr/play/lp2/confirmer',
    de: '/de/play/lp2/bestatigen',
    Component: 'LandingOneBuyTwo',
    lp: 'lp2',
    hideOnProd: 'hidden',
  },
  {
    en: '/play/lp2/buy2',
    fr: '/fr/play/lp2/acheter2',
    de: '/de/play/lp2/kaufen2',
    Component: 'LandingOneBuyOne',
    lp: 'lp2',
    buy: 'buy2',
    hideOnProd: 'hidden',
  },
  { en: '/media', fr: '/fr/medias', de: '/de/medien', Component: 'Media', id: 'media' },
  {
    en: '/media/screenshots',
    fr: '/fr/media/screenshots',
    de: '/de/media/screenshots',
    Component: 'ScreenshotComponent',
    id: 'screenshots',
  },
  {
    en: '/media/videos',
    fr: '/fr/media/videos',
    de: '/de/media/videos',
    Component: 'VideoComponent',
    id: 'videos',
  },
  {
    en: '/media/wallpapers',
    fr: '/fr/media/wallpapers',
    de: '/de/media/wallpapers',
    Component: 'WallpaperComponent',
    id: 'wallpapers',
  },
  {
    en: '/media/conceptart',
    fr: '/fr/media/conceptart',
    de: '/de/media/conceptart',
    Component: 'ConceptArtComponent',
    id: 'concept-art',
  },
  {
    en: '/media/document',
    fr: '/fr/media/document',
    de: '/de/media/document',
    Component: 'DocumentComponent',
    id: 'documents',
  },
  {
    en: '/launcher/news',
    fr: '/fr/launcher/news',
    de: '/de/launcher/news',
    Component: 'LauncherComponent',
    id: 'launcher-news',
  },
  {
    en: '/launcher/news/:uid',
    fr: '/fr/launcher/news/:uid',
    de: '/de/launcher/news/:uid',
    Component: 'LauncherDetailComponent',
    id: 'launcher-detail',
  },
  {
    en: '/launcher/patch',
    fr: '/fr/launcher/patch',
    de: '/de/launcher/patch',
    Component: 'PatchComponent',
    id: 'launcher-patch',
  },
  {
    en: '/launcher/patch/:uid',
    fr: '/fr/launcher/patch/:uid',
    de: '/de/launcher/patch/:uid',
    Component: 'PatchComponent',
    id: 'launcher-patch-detail',
  },
  {
    en: '/launcher/community',
    fr: '/fr/launcher/community',
    de: '/de/launcher/community',
    Component: 'CommunityComponent',
    id: 'launcher-community',
  },
  { en: '/partners', fr: '/fr/partenaires', de: '/de/partner', Component: 'PartnerComponent' },
  {
    en: '/pillar/building',
    fr: '/fr/pillar/building',
    de: '/de/pillar/building',
    Component: 'BuildingComponent',
    id: 'pillar-building',
    hideOnProd: 'hidden',
  },
  {
    en: '/pillar/builders',
    fr: '/fr/pillar/builders',
    de: '/de/pillar/builders',
    Component: 'BuildersComponent',
    id: 'pillar-builders',
  },
  {
    en: '/pillar/merchants',
    fr: '/fr/pillar/merchants',
    de: '/de/pillar/merchants',
    Component: 'BuildersComponent',
    id: 'pillar-merchants',
  },
  {
    en: '/pillar/fighters',
    fr: '/fr/pillar/fighters',
    de: '/de/pillar/fighters',
    Component: 'BuildersComponent',
    id: 'pillar-fighters',
  },
  {
    en: '/pillar/leaders',
    fr: '/fr/pillar/leaders',
    de: '/de/pillar/leaders',
    Component: 'BuildersComponent',
    id: 'pillar-leaders',
  },
  {
    en: '/updates',
    fr: '/fr/updates',
    de: '/de/updates',
    Component: 'UpdatesComponent',
    id: 'updates',
  },
  {
    en: '/updates/:uid',
    fr: '/fr/updates/:uid',
    de: '/de/updates/:uid',
    Component: 'UpdatesComponent',
    id: 'updates',
  },
  {
    en: '/landing',
    fr: '/fr/landing',
    de: '/de/landing',
    Component: 'LandingComponent',
    id: 'lp',
  },
  {
    en: '/landing/:uid',
    fr: '/fr/landing/:uid',
    de: '/de/landing/:uid',
    Component: 'LandingComponent',
    id: 'lp',
  },
  {
    en: '/lottery',
    fr: '/fr/lottery',
    de: '/de/lottery',
    Component: 'ContestComponent',
    hideOnProd: 'hidden',
  },
  {
    en: '/lottery/thankyou',
    fr: '/fr/lottery/thankyou',
    de: '/de/lottery/thankyou',
    Component: 'ContestThankYouComponent',
    hideOnProd: 'hidden',
  },
];
