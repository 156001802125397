import React, { useState } from 'react';
import BuyPagePackages from './buypage-one-blocks/buypage-packages';
// import BuyPageInfo from './buypage-one-blocks/buypage-info';
// import BuyPageLoginDisclaim from './buypage-one-blocks/buypage-LoginDisclaim';
import BuyPageTwo from './buypage-two-blocks/buypage-two';
import BuyPageSpecs from './buypage-one-blocks/buypage-specs';
import { usePlansAvailable } from '../../stores/selectors/plans-available.selector';
import { usePrismic } from '../../hooks/prismic.hook';
import { getPageID } from '../../services/i18n/i18n.url.service';
import { useForm } from 'react-hook-form';
import subscribeToNewsletter from '../../services/authentication/newsletter.service';
import PrismicRichText, { asText } from '../../services/prismic/prismic.richtext.service';
import { AnalyticsComponent } from '../analytics/analytics.component';
import Modal from 'react-modal';
import { plansAvailableResponse } from '../../stores/actions/plans-available.action';
import { useAppDispatch } from '../../stores/app.store.config';
import { usePlan } from '../../stores/selectors/plan.selector';
import config from '../../../config';
import { useLocation } from 'react-router-dom';
import LinkComponent from '../link/link.component';
const BuyPageOne = (): JSX.Element => {
  const { handleSubmit, register, errors } = useForm();
  const dispatch = useAppDispatch();
  const onSubmit = async (values: Record<string, any>): Promise<void> => {
    const { email } = values;
    await subscribeToNewsletter(email);
  };

  const { pathname } = useLocation();

  const [limitHeader, setLimitHeader] = useState(true);
  const [popUp, setPopUp] = useState(false);

  const { contents, hasErrors } = usePlansAvailable();
  const { contents: plan } = usePlan();

  const { currency } = plan || {};
  const [openModal, setOpenModal] = useState(false);
  const currencySelectedParser = (code: string): string => {
    switch (code) {
      case 'CAD':
        return 'Canadian Dollars ($)';
      case 'USD':
        return 'Dollars ($)';
      case 'EUR':
        return 'Euros (€)';
      case 'AUD':
        return 'Australian Dollars ($)';
      case 'GBP':
        return 'Pounds (£)';
      default:
        return '';
    }
  };
  const { limit, percentageUsed } = contents;
  const [selectedCurrency, setSelectedCurrency] = useState(currencySelectedParser(currency || 'USD'));
  const {
    result: {
      limit_not_reached,
      limit_reached,
      popup_header,
      sold,
      join,
      max,
      click_here,
      stay_informed,
      problem,
      choose_pack,
      confirm_pack,
      checkout,
      steam_btn_left_label,
      steam_btn_right_label,
      steam_btn_left_link,
      steam_btn_right_link,
    },
    contents: contentBuyPage,
    data: { play_everywhere_image, play_everywhere_title, play_everywhere_desc, card_notice },
  } = usePrismic('buy_page');
  const {
    data: { modal_description, modal_title, modal_btn_label, modal_btn_link, logo, bg_image_medium },
  } = usePrismic('nvidia');
  const {
    result: { email_placeholder, required },
  } = usePrismic('common');

  if (!contentBuyPage) return <></>;

  const {
    results: [
      {
        data: { popup_disclaimer },
      },
    ],
  } = contentBuyPage;

  const isDefaultCountry = () => {
    return (
      contents?.ipData?.country_code === 'US' ||
      contents?.ipData?.country_code === 'AU' ||
      contents?.ipData?.country_code === 'GB' ||
      config.europeanCountries?.includes(contents?.ipData?.country_code) ||
      contents?.ipData?.country_code === 'CA'
    );
  };
  const uid = getPageID(pathname) === 'buy-confirm' ? '2' : '1';
  const changeCurrency = (currency: string): void => {
    dispatch(
      plansAvailableResponse({
        ipData: contents.ipData,
        usefulData: contentBuyPage.results[0].data,
        currency,
      }),
    );
  };
  if (uid === '1') {
    if (hasErrors) {
      return (
        <div className="errorMessage">
          <p>{problem || 'An error occured. Please refresh the page.'}</p>
        </div>
      );
    }
    return (
      <>
        <AnalyticsComponent type="buy" />
        {limitHeader && limit && percentageUsed != 100 && (
          <div className={'limitHeader'}>
            <div className="displayMessage">
              <p>{limit_not_reached}</p>
            </div>

            <div className={'progressBar'}>
              <div className={'barLimit'} />
              <div className={'barUsed'} style={{ width: `${percentageUsed}%` }} />

              <p className="left">
                {percentageUsed}
                {sold}
              </p>
              <p className="right">{max}</p>
            </div>

            <div
              className="close"
              onClick={(): void => {
                setLimitHeader(false);
              }}
            />
          </div>
        )}
        {limitHeader && limit && percentageUsed === 100 && (
          <div className={' limitHeaderTwo limitHeader'}>
            <div className="displayMessage">
              <p>
                {limit_reached}, <span onClick={(): void => setPopUp(true)}>{click_here}</span>
                {stay_informed}
              </p>
            </div>

            <div
              className="close"
              onClick={(): void => {
                setLimitHeader(false);
              }}
            />
          </div>
        )}
        {popUp && (
          <>
            <div className={'parentBlur'} />

            <div className={'popUpWaitingList'}>
              <div className={'close'} onClick={(): void => setPopUp(false)} />

              <div className="footeremail">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <p className="popUpheader">{popup_header}</p>
                  <div className="util-input">
                    <div className="first" />
                    <input
                      type={'email'}
                      name={'email'}
                      className="util-input-text"
                      placeholder={email_placeholder}
                      ref={register({
                        required,
                      })}
                    />

                    <div className="last" />
                  </div>
                  <button type="submit">
                    <div className="icon-send"></div>
                    <span>{join}</span>
                  </button>
                  {errors.email && <p style={{ color: 'red' }}>{errors.email.message}</p>}
                  <PrismicRichText render={popup_disclaimer} className="disclaimer" />
                </form>
              </div>
              <p className="disclaimer"></p>
            </div>
          </>
        )}
        <div className="textButtonContainerMain">
          <div className="bread-item-container">
            <p className="bread-item active">{choose_pack}</p>
            <p className="bread-item ">{confirm_pack}</p>
            <p className="bread-item">{checkout}</p>
            <p className="bread-item">Thank you</p>
          </div>
          <div className="bread-item-container mobile">
            <p className="bread-item active">{choose_pack}</p>
            <p className="bread-item "></p>
            <p className="bread-item"></p>
          </div>
          {(!isDefaultCountry() || config.local) && (
            <div className="change-currency">
              <a className="change-currency-item"> Currency: {selectedCurrency}</a>
              <a className="change-currency-item mobile-item"> {selectedCurrency}</a>
              <div className="change-currency-options">
                <div className="corners">
                  <a
                    className={selectedCurrency === 'Euros (€)' ? 'selected' : ''}
                    onClick={() => {
                      changeCurrency('EU');
                      setSelectedCurrency('Euros (€)');
                    }}
                  >
                    <input
                      className=""
                      onChange={() => {
                        return;
                      }}
                      checked={selectedCurrency === 'Euros (€)' ? true : false}
                      type="checkbox"
                    />
                    Euros (€)
                  </a>
                  <a
                    className={selectedCurrency == 'Dollars ($)' ? 'selected' : ''}
                    onClick={() => {
                      changeCurrency('USD');
                      setSelectedCurrency('Dollars ($)');
                    }}
                  >
                    <input
                      className=""
                      onChange={() => {
                        return;
                      }}
                      checked={selectedCurrency === 'Dollars ($)' ? true : false}
                      type="checkbox"
                    />
                    Dollars ($)
                  </a>
                  <a
                    className={selectedCurrency == 'Pounds (£)' ? 'selected' : ''}
                    onClick={() => {
                      changeCurrency('GBP');
                      setSelectedCurrency('Pounds (£)');
                    }}
                  >
                    <input
                      className=""
                      onChange={() => {
                        return;
                      }}
                      checked={selectedCurrency === 'Pounds (£)' ? true : false}
                      type="checkbox"
                    />
                    Pounds (£)
                  </a>
                  <a
                    className={selectedCurrency === 'Canadian Dollars ($)' ? 'selected' : ''}
                    onClick={() => {
                      changeCurrency('CAD');
                      setSelectedCurrency('Canadian Dollars ($)');
                    }}
                  >
                    <input
                      className=""
                      onChange={() => {
                        return;
                      }}
                      checked={selectedCurrency === 'Canadian Dollars ($)' ? true : false}
                      type="checkbox"
                    />
                    Canadian Dollars ($)
                  </a>
                  <a
                    className={selectedCurrency == 'Australian Dollars ($)' ? 'selected' : ''}
                    onClick={() => {
                      changeCurrency('AUD');
                      setSelectedCurrency('Australian Dollars ($)');
                    }}
                  >
                    <input
                      className=""
                      onChange={() => {
                        return;
                      }}
                      checked={selectedCurrency === 'Australian Dollars ($)' ? true : false}
                      type="checkbox"
                    />
                    Australian Dollars ($)
                  </a>
                </div>
              </div>
            </div>
          )}
        </div>

        {card_notice && <PrismicRichText className="buypage-notice" render={card_notice} />}

        <div className="buypage-container">
          <div className="background-buy-page"></div>

          <BuyPagePackages />
          {/* <BuyPageLoginDisclaim /> */}
          <div
            className="play-everywhere-container"
            style={{
              backgroundImage: `linear-gradient(92deg, #000 12%, rgba(0, 0, 0, 0) 59%),url(${play_everywhere_image?.url})`,
            }}
          >
            <div className="box-corners">
              <div className="play-everywhere-title">
                <PrismicRichText render={play_everywhere_title} />
              </div>
              <div className="play-everywhere-desc">
                <PrismicRichText render={play_everywhere_desc} />
              </div>
              <div className="steam-btns">
                {steam_btn_left_link && (
                  <LinkComponent className="btn-wrapper" link={steam_btn_left_link}>
                    <button className="theme-button-spec-positive joinBeta green-gradient-btn">
                      <div className="theme-button-container">
                        <div />
                        <span>{steam_btn_left_label}</span>
                      </div>
                    </button>
                  </LinkComponent>
                )}
                {steam_btn_right_link && (
                  <LinkComponent className="btn-wrapper" link={steam_btn_right_link}>
                    <button className="theme-button-spec-positive joinBeta tryFree">
                      <div className="theme-button-container">
                        <div />
                        <span>{steam_btn_right_label}</span>
                      </div>
                    </button>
                  </LinkComponent>
                )}
                {steam_btn_right_link && (
                  <button
                    className="theme-button-spec-positive tryFree geforce"
                    onClick={(): void => {
                      if (setOpenModal) setOpenModal(true);
                    }}
                  >
                    <div className="theme-button-container">
                      <div />
                      <span>DU ON GEFORCE NOW</span>
                    </div>
                  </button>
                )}
              </div>
            </div>
          </div>
          <BuyPageSpecs setOpenModal={setOpenModal} />
          {/* <NvidiaComponent size="small" setOpenModal={setOpenModal} /> */}
          <Modal
            isOpen={openModal}
            onRequestClose={(): void => {
              setOpenModal(false);
            }}
            shouldCloseOnOverlayClick={true}
            className="modal-content modal-content-nvidia"
            overlayClassName="modal-overlay"
          >
            <div className="nvidia-content">
              <div className="corners"></div>
              <button
                className="x-button close-button"
                onClick={(): void => {
                  setOpenModal(false);
                }}
              />
              <div
                className="nvidia-image-intro"
                style={{
                  backgroundImage: `url(${bg_image_medium?.url})`,
                }}
              >
                <div
                  className="nvidia-logo"
                  style={{
                    backgroundImage: `url(${logo?.url})`,
                  }}
                />
              </div>
              <div className="nvidia-description-title">
                <PrismicRichText render={modal_title} />
              </div>
              <div className="nvidia-description">
                <PrismicRichText render={modal_description} />
              </div>
              <a className="util-button-continue nvidia" href={modal_btn_link?.url} target="_blank" rel="noreferrer">
                <div />
                <span>{asText(modal_btn_label)}</span>
              </a>
            </div>
          </Modal>
          {/* <BuyPageInfo /> */}
        </div>
      </>
    );
  } else if (uid === '2') {
    return (
      <>
        <div className="textButtonContainerMain">
          <div className="bread-item-container">
            <p className="bread-item">{choose_pack}</p>
            <p className="bread-item active">{confirm_pack}</p>
            <p className="bread-item">{checkout}</p>
            <p className="bread-item">Thank you</p>
          </div>
          <div className="bread-item-container mobile">
            <p className="bread-item checkout"></p>
            <p className="bread-item checkout active">{confirm_pack}</p>
            <p className="bread-item checkout"></p>
          </div>
        </div>
        <BuyPageTwo />
      </>
    );
  } else return <> </>;
};

export default BuyPageOne;
