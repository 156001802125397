import { AuthState } from '../../models/authentication/auth.model';
import { getUrlFromEnglish } from '../../services/i18n/i18n.url.service';
import { goto } from '../../stores/selectors/router.selector';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../stores/selectors/auth.selector';
import Popup from 'reactjs-popup';
import Carousel from 'react-multi-carousel';
import PrismicRichText, { asText } from '../../services/prismic/prismic.richtext.service';
import { usePrismic } from '../../hooks/prismic.hook';
import axios from 'axios';
import { SnackBarComponent } from '../snackBar/snackBar.component';
import { displayDate } from '../../services/utils/utils.service';
interface ApiErrorResponse {
  type?: string;
  title?: string;
  status?: number;
  traceId?: string;
  detail?: string;
  error?: string;
}
const ExportBlueprintComponent = (): JSX.Element | null => {
  const { user }: AuthState = useAuth();
  const [blueprints, setBlueprints] = useState([]);
  const [waiverPlayerState, setWaiverPlayerState] = useState(false);
  const [orgWaivers, setOrgWaivers] = useState<{ waiver: boolean; name: string; id: number; tag: string }[]>([]);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (!user) {
      goto(getUrlFromEnglish('/login'));
    } else {
      retrieveBlueprints();
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      getWaiverState();
    }
  }, [waiverPlayerState]);

  const handleErrorCentral = (error: any) => {
    if (axios.isAxiosError(error)) {
      const errorData = error.response?.data as ApiErrorResponse;
      const errorMessage = errorData.error || errorData.detail || error.message || 'An error occurred';
      setErrorMessage('An error occurred: ' + errorMessage);
    } else {
      setErrorMessage('An error occurred' + error.message);
    }
  };

  const retrieveBlueprints = async (): Promise<void> => {
    try {
      const response = await axios.get(`/api/blueprint/list`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      setBlueprints(response.data);
    } catch (err: any) {
      handleErrorCentral(err);
    }
  };

  const downloadBlueprint = async (bpId: number): Promise<void> => {
    try {
      const response = await axios.get(`/api/blueprint/download/${bpId}`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `blueprint-${bpId}.zip`);
      document.body.appendChild(link);
      link.click();

      if (link.parentNode) link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (err: any) {
      handleErrorCentral(err);
    }
  };

  const getWaiverState = async (): Promise<void> => {
    try {
      const response = await axios.get(`/api/blueprint/waiverstate`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      setWaiverPlayerState(response.data?.playerWaiver);
      setOrgWaivers(response.data?.orgWaivers);
    } catch (err: any) {
      handleErrorCentral(err);
    }
  };
  const changeWaiverState = async (enabled: boolean): Promise<void> => {
    try {
      await axios.post(
        `/api/blueprint/waiverplayer/${enabled}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        },
      );
      getWaiverState();
    } catch (err: any) {
      handleErrorCentral(err);
    }
  };
  const changeOrganizationWaiverState = async (oid: number, enabled: boolean): Promise<void> => {
    try {
      await axios.post(
        `/api/blueprint/waiverorganization/${oid}/${enabled}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        },
      );
      getWaiverState();
    } catch (err: any) {
      handleErrorCentral(err);
    }
  };

  const contentStyle = { background: '#ffd255' };
  const overlayStyle = { background: 'transparent' };
  const arrowStyle = { color: '#ffd255' };

  const { contents } = usePrismic('export_blueprint');

  if (!contents) return <></>;

  const {
    results: [
      {
        data: {
          title,
          description,
          box_left_title,
          box_left_desc,
          box_left_feedback,
          box_right_feedback,
          waiver_desc,
          waiver_title,
          about_title,
          about_description,
          about_content,
          exported_images,
          generate_button,
        },
      },
    ],
  } = contents || {};

  const responsive = {
    desktop: {
      breakpoint: { max: 4000, min: 480 },
      items: 1,
    },

    mobile: {
      breakpoint: { max: 480, min: 0 },
      items: 1,
    },
  };

  // const testList = [
  //   {
  //     name: 'Blueprint1',
  //     createdAt: '2024/2/3',
  //     drmProtected: true,
  //     creatorUsername: 'Cyntia',
  //     creatorOrganizationName: 'OrganizationParent',
  //   },
  // ];
  // const testOrganizations = [{ id: '1', name: 'Jupiter', tag: 'sty', waiver: false }];

  const renderCarousel = (items: Array<Record<string, any>>): JSX.Element[] | JSX.Element => {
    return items?.map((item: Record<string, any>, key: number) => {
      return (
        <div key={key}>
          <div className="carousel-box" style={{ backgroundImage: `url(${item?.image?.url})` }}></div>
          <div className="carousel-info-content"></div>
        </div>
      );
    });
  };
  return (
    <div className="export-blueprint-container">
      {errorMessage && <SnackBarComponent message={errorMessage} type="failure" />}
      <div className="export-blueprint__intro">
        <div className="export-blueprint__intro-title">
          <PrismicRichText render={title}></PrismicRichText>
        </div>
        <div className="export-blueprint__intro-desc">
          <PrismicRichText render={description}></PrismicRichText>
        </div>
      </div>
      <div className="export-blueprint__grid-container">
        <div className="export-blueprint__grid">
          <div className="export-blueprint__corners">
            <div className="export-blueprint__title">{asText(box_left_title)}</div>
            <div className="export-blueprint__desc">
              <PrismicRichText render={box_left_desc}></PrismicRichText>
            </div>
            {blueprints.length == 0 && (
              <p className="export-blueprint__desc export-blueprint__desc--right">{asText(box_left_feedback)}</p>
            )}
            {blueprints.length > 0 && (
              <div className="export-blueprint__items">
                {blueprints.map((blueprint: any, key: number): any => (
                  <div className="export-blueprint__item" key={key}>
                    <div className="export-blueprint__item-image-info">
                      <div className="export-blueprint__item-image"></div>
                      <div className="export-blueprint__item-info">
                        <div className="export-blueprint__item-name">{blueprint.name}</div>
                        <div className="export-blueprint__item-desc">
                          Creation date: {displayDate(blueprint.createdAt)}
                        </div>
                        <div className="export-blueprint__item-desc">
                          DRM protected: {blueprint.drmProtected ? 'yes' : 'no'}
                        </div>
                        <div className="export-blueprint__item-desc">
                          Creator: {blueprint.creatorUsername || blueprint.creatorOrganizationName || 'Orphaned'}
                        </div>
                      </div>
                    </div>
                    <Popup
                      on="hover"
                      trigger={(): JSX.Element => (
                        <button
                          className="export-blueprint__item-btn"
                          onClick={() => {
                            downloadBlueprint(blueprint.id);
                          }}
                        >
                          <span className="export-blueprint__btn-label">{asText(generate_button)}</span>
                        </button>
                      )}
                      {...{ contentStyle, overlayStyle, arrowStyle }}
                      position="top center"
                    >
                      <div className="tooltip-msg">
                        <p>Click to start the generation of this model</p>
                      </div>
                    </Popup>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="export-blueprint__grid export-blueprint__grid--right">
          <div className="export-blueprint__corners">
            <div className="export-blueprint__title">{asText(waiver_title)}</div>
            <div className="export-blueprint__desc export-blueprint__desc--right">
              <PrismicRichText render={waiver_desc}></PrismicRichText>
            </div>

            <div className="export-blueprint__item">
              <div className="export-blueprint__item-image-info">
                <div className="export-blueprint__item-image"></div>
                <div className="export-blueprint__item-info">
                  <div className="export-blueprint__item-name">Player</div>
                </div>
              </div>
              <Popup
                on="hover"
                trigger={(): JSX.Element => (
                  <button
                    className="export-blueprint__item-btn"
                    onClick={() => {
                      changeWaiverState(!waiverPlayerState);
                      setErrorMessage('');
                    }}
                  >
                    <span className="export-blueprint__btn-label">waive DRW</span>
                    {waiverPlayerState && <div className="export-blueprint__btn-state">On</div>}
                    {!waiverPlayerState && (
                      <div className="export-blueprint__btn-state export-blueprint__btn-state--off">Off</div>
                    )}
                  </button>
                )}
                {...{ contentStyle, overlayStyle, arrowStyle }}
                position="top center"
              >
                <div className="tooltip-msg">
                  <p>Your current state is {waiverPlayerState ? 'On' : 'Off'}. CLick to change it</p>
                </div>
              </Popup>
            </div>

            <div className="export-blueprint__desc">
              <p>Organizations</p>
            </div>
            {orgWaivers.length == 0 && (
              <p className="export-blueprint__desc export-blueprint__desc--right">{asText(box_right_feedback)}</p>
            )}
            {orgWaivers.length > 0 && (
              <div className="export-blueprint__items export-blueprint__items--right">
                {orgWaivers.map((organization: any, key: number): any => (
                  <div className="export-blueprint__item" key={key}>
                    <div className="export-blueprint__item-image-info">
                      <div className="export-blueprint__item-image"></div>
                      <div className="export-blueprint__item-info">
                        <div className="export-blueprint__item-name">{organization.name}</div>
                      </div>
                    </div>
                    <Popup
                      on="hover"
                      trigger={(): JSX.Element => (
                        <button
                          className="export-blueprint__item-btn"
                          onClick={() => {
                            changeOrganizationWaiverState(organization.id, !organization.waiver);
                            setErrorMessage('');
                          }}
                        >
                          <span className="export-blueprint__btn-label">waive DRW</span>
                          {orgWaivers[key].waiver && <div className="export-blueprint__btn-state">On</div>}
                          {!orgWaivers[key].waiver && (
                            <div className="export-blueprint__btn-state export-blueprint__btn-state--off">Off</div>
                          )}
                        </button>
                      )}
                      {...{ contentStyle, overlayStyle, arrowStyle }}
                      position="top center"
                    >
                      <div className="tooltip-msg">
                        <p>
                          The organization current state is {orgWaivers[key].waiver ? 'On' : 'Off'}. CLick to change it
                        </p>
                      </div>
                    </Popup>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="export-blueprint__carousel">
        <div className="section-text">
          <div className="slice-title">
            <PrismicRichText render={about_title}></PrismicRichText>
          </div>
          <div className="slice-subtitle">
            <PrismicRichText render={about_description}></PrismicRichText>
          </div>

          <PrismicRichText render={about_content}></PrismicRichText>
        </div>
        <div className="section-carousel">
          <Carousel
            autoPlay={false}
            autoPlaySpeed={5000}
            transitionDuration={1000}
            keyBoardControl={true}
            responsive={responsive}
            infinite={true}
            itemClass="carousel-item-padding"
          >
            {renderCarousel(exported_images || [])}
          </Carousel>
        </div>
      </div>
    </div>
  );
};
export default ExportBlueprintComponent;
