import React from 'react';
import PrismicRichText, { asText } from '../../../services/prismic/prismic.richtext.service';
import { usePrismic } from '../../../hooks/prismic.hook';
import LinkComponent from '../../link/link.component';
import Popup from 'reactjs-popup';
import { useAuth } from '../../../stores/selectors/auth.selector';
import { AuthState } from '../../../models/authentication/auth.model';

const AccountDownloadServer = (): JSX.Element => {
  const { user }: AuthState = useAuth();
  const {
    data: { account_server_download_title, server_download_title, server_download_desc },
  } = usePrismic('account_download_server');
  const {
    data: { mydu_image, mydu_title, mydu_desc, mydu_link, mydu_button_label, mydu_popup_content, mydu_notice },
  } = usePrismic('account_download');
  const contentStyle = { background: '#ffd255' };
  const overlayStyle = { background: 'transparent' };
  const arrowStyle = { color: '#ffd255' };
  return (
    <>
      <div className="download-container">
        <h2> {asText(account_server_download_title)}</h2>
        <div className="download-box">
          <div className="download-box-corners">
            <div className="download-box-padding">
              <div className="download-box-du-image" style={{ backgroundImage: `url(${mydu_image?.url})` }}></div>
              <div className="download-box-du-title">
                <PrismicRichText render={mydu_title} />
              </div>
              <div className="download-box-du-desc">
                <PrismicRichText render={mydu_desc} />
              </div>
              {!user?.details?.hasMyDUAccess && (
                <>
                  <Popup
                    className="tooltip-container"
                    trigger={(): JSX.Element => (
                      <div>
                        <LinkComponent className="button-container" link={mydu_link?.url}>
                          <button disabled={true} className="theme-button">
                            <div className="theme-button-container">
                              <div></div>
                              <span>{asText(mydu_button_label)}</span>
                            </div>
                          </button>
                        </LinkComponent>
                      </div>
                    )}
                    position="top center"
                    on="hover"
                    {...{ contentStyle, overlayStyle, arrowStyle }}
                    closeOnDocumentClick
                  >
                    <div className="popup-content-tooltip">
                      <span>{asText(mydu_popup_content)}</span>
                    </div>
                  </Popup>
                  <div className="download-box-myDu-notice">
                    <PrismicRichText render={mydu_notice} />
                  </div>
                </>
              )}
              {user?.details?.hasMyDUAccess && (
                <LinkComponent className="button-container" link={mydu_link?.url}>
                  <button disabled={!user?.details?.hasMyDUAccess} className="theme-button">
                    <div className="theme-button-container">
                      <div></div>
                      <span>{asText(mydu_button_label)}</span>
                    </div>
                  </button>
                </LinkComponent>
              )}
            </div>
          </div>
        </div>
        <div className="download-box server">
          <div className="download-box-corners">
            <div className="download-box-padding">
              <div className="download-box-du-title">
                <PrismicRichText render={server_download_title} />
              </div>
              <div className="download-box-du-desc">
                <PrismicRichText render={server_download_desc} />
              </div>
              {/* <div className="buttons">
                <LinkComponent className="button-container" link={server_download_button_link?.url}>
                  <button className="theme-button">
                    <div className="theme-button-container">
                      <div></div>
                      <span>{asText(server_download_button_label)}</span>
                    </div>
                  </button>
                </LinkComponent>
                <LinkComponent className="button-container" link={server_download_doc_link?.url}>
                  <button className="theme-button">
                    <div className="theme-button-container">
                      <div></div>
                      <span>{asText(server_download_doc)}</span>
                    </div>
                  </button>
                </LinkComponent>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountDownloadServer;
